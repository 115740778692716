<template>
  <div>
    <portal to="pageHeaderRight">
      <router-button
        v-if="$store.state.is_godfather"
        routeName="BrandDescribeEdit"
        :routeParam="$route.params.pathName"
        :text="$t('app.edit_description')"
        icon="icon-icon-edit-pencil-line"
        cssClass="ab-button--white"
      ></router-button>

      <span
        v-if="$store.state.is_godfather"
        class="ab-button--white"
        @click="createPage('BrandDetailEdit', page)"
        ><span>{{ $t("app.create") }}</span> <i class="icon-plus"
      /></span>
    </portal>
    <div class="grid-x grid-margin-x">
      <div class="cell medium-9">
        <view-content-repeater
          :content-items="brandData.value"
        ></view-content-repeater>
      </div>
      <div class="cell medium-3">
        <aside>
          <view-content-repeater
            :content-items="brandData.sidebar"
          ></view-content-repeater>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import ViewContentRepeater from '@/views/brand/components/ViewContentRepeater'
import NewPageModal from '@/views/brand/components/NewPageModal'

export default {
  name: 'BrandDetail',
  data () {
    return {
      id: null,
      brandData: {
        value: [],
        sidebar: []
      },
      page: {
        name: '',
        slug: ''
      }
    }
  },
  mounted () {
    this.fetchData()
  },

  methods: {
    fetchData () {
      backend
        .get(
          `${process.env.VUE_APP_URL}/pages/${this.$route.params.pathName}/describe`
        )
        .then(response => {
          this.page.name = response.data.name
          this.page.slug = response.data.slug
          this.brandData.value = response.data.value
          this.brandData.sidebar = response.data.sidebar
        })
    },
    createPage (routeName, page) {
      this.$modal.show(
        NewPageModal,
        {
          routeName: routeName,
          page: page
        },
        {
          draggable: false,
          scrollable: false,
          classes: 'text-center',
          height: 'auto',
          width: '900px'
        }
      )
    }
  },
  components: {
    ViewContentRepeater
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  }
}
</script>
